export const defaultValuesSim = {
  phoneNumber: '',
  imei: '',
};

export const listStatus = [
  {
    value: 1,
    label: 'Online',
  },
  {
    value: 0,
    label: 'Offline',
  },
];

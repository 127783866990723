export interface IAgency {
  id: string;
  parentId: string | null;
  name: string;
  address: string;
  phone: string;
  username: string;
}

export const agencies = [
  {
    id: 'f3b958b7-91f1-49f3-a95f-03d79057c22f',
    parentId: null,
    name: 'Đại lý 1',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
  {
    id: 'cfd4c8bc-53ab-4da5-abfc-d1b815b77b0e',
    parentId: null,
    name: 'Đại lý 2',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
  {
    id: '3d62781f-b2b6-437c-89d6-70da579dac03',
    parentId: 'cfd4c8bc-53ab-4da5-abfc-d1b815b77b0e',
    name: 'Đại lý 3',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
  {
    id: '65f8d8c0-b490-4709-a5bc-47a602d9f592',
    parentId: 'cfd4c8bc-53ab-4da5-abfc-d1b815b77b0e',
    name: 'Đại lý 4',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
  {
    id: '54231a73-bc53-4214-9dd8-3233ba013c03',
    parentId: 'cfd4c8bc-53ab-4da5-abfc-d1b815b77b0e',
    name: 'Đại lý 5',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
  {
    id: 'f529ba99-b374-4f34-857c-bbbf8f09bbce',
    parentId: '54231a73-bc53-4214-9dd8-3233ba013c03',
    name: 'Đại lý 6',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
  {
    id: '3ec0222d-0d1f-49cc-9a84-4f1ae07085ab',
    parentId: '54231a73-bc53-4214-9dd8-3233ba013c03',
    name: 'Đại lý 7',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
  {
    id: 'b6a3e46e-33b8-4de7-b80e-b758aa2c7d17',
    parentId: null,
    name: 'Đại lý 8',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
  {
    id: '0f32c223-4e83-4724-ad35-4ea62dc42f9c',
    parentId: null,
    name: 'Đại lý 9',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
  {
    id: 'c59936b7-734d-404c-a4f3-8182796877b7',
    parentId: null,
    name: 'Đại lý 10',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
  {
    id: '45edf6d7-7d77-4010-af62-8ff8b7d2390c',
    parentId: null,
    name: 'Đại lý 11',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
  {
    id: '185cbba5-3e18-4466-9b56-0dc09f80cb77',
    parentId: null,
    name: 'Đại lý 12',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
  {
    id: '973eaedc-899f-4645-8e9b-4baf0c7ed334',
    parentId: null,
    name: 'Đại lý 13',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
  {
    id: '56dab834-dd02-4abf-a62f-c54a55331134',
    parentId: null,
    name: 'Đại lý 14',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
  {
    id: 'b378b858-e621-464d-9067-229f42fff352',
    parentId: null,
    name: 'Đại lý 15',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
  {
    id: '889da7df-4f1f-4cf8-8107-d193e4542958',
    parentId: null,
    name: 'Đại lý 16',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
  {
    id: 'a77caa76-2335-45de-94c7-14eb385548c9',
    parentId: null,
    name: 'Đại lý 17',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
  {
    id: 'e2754748-4f02-49f2-8775-72751dca3fb7',
    parentId: null,
    name: 'Đại lý 18',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
  {
    id: 'fc48f618-0c1f-448c-bf9b-da0d90949f24',
    parentId: null,
    name: 'Đại lý 19',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
  {
    id: 'efe5c184-ba18-4abf-9db7-8d9bcfbc7c5d',
    parentId: null,
    name: 'Đại lý 20',
    address: 'Số 3, Ngõ 51, Quần Ngựa, Liễu Giai, Ba Đình, Hà Nội',
    phone: '0866666666',
    username: 'admin_agency',
  },
];

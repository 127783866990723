import React from 'react';
import { Box, Button, DialogActions, DialogContent, Switch, Typography } from '@mui/material';
import FormikWrappedField from '../../../../common/input/Field';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from '../../../../common/modal/Modal';
import { useAddCameraMutation, useUpdateCameraControlMutation } from '../../../../services/control.service';
import { useAuth } from '../../../../hooks/useAuth';
import { useSnackbar } from '../../../../hooks/useSnackbar';

interface IInitialValues {
  boxid: string;
  camId: string;
  name: string;
  serial: string;
}

interface Props {
  locationId: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  initialValues: IInitialValues;
}

const validationSchema = {
  name: Yup.string().required('Tên camera không được để trống'),
};

export const EditCameraDialog: React.FC<Props> = ({ locationId, open, onClose, onSuccess, initialValues }) => {
  const [updateCameraControl] = useUpdateCameraControlMutation();
  const {
    auth: { currentUser },
  } = useAuth();

  const { setSnackbar } = useSnackbar();
  const { camId, boxid, name } = initialValues;

  const formik = useFormik({
    initialValues: {
      name: name,
      enabled_cloudStorage: true,
      enabled_localStorage: true,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      ...validationSchema,
    }),
    onSubmit: (values) => {
      if (currentUser && locationId) {
        updateCameraControl({
          data: values,
          agencyId: currentUser.sub_id,
          locationId: locationId,
          boxId: boxid,
          camId: camId,
        }).then((res: any) => {
          if (res.error) {
            setSnackbar({
              open: true,
              message: 'Cập nhập thông tin camera không thành công',
              severity: 'error',
            });
            return;
          }
          setSnackbar({ open: true, message: 'Cập nhập thông tin camera thành công', severity: 'success' });
          onSuccess();
        });
        onClose?.();
      }
    },
  });
  const { handleSubmit, getFieldProps, isValid, dirty, values, setFieldValue } = formik;

  return (
    <Modal size="sm" show={open} close={onClose} title={'Sửa thông tin camera'}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogContent
            sx={{
              padding: 0,
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              overflow: 'hidden',
              marginBottom: '32px',
            }}
          >
            <FormikWrappedField
              style={{ width: 600 }}
              placeholder="Nhập tên camera"
              topLable="Tên camera"
              {...getFieldProps('name')}
            />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>
              <Box display="flex" alignItems="center">
                <Typography sx={{ fontSize: '14px', fontWeight: '400', lineHeight: '22px' }}>
                  CloudStorage status
                </Typography>
                <Switch
                  sx={{ m: 1 }}
                  checked={values.enabled_cloudStorage}
                  onChange={() => setFieldValue('enabled_cloudStorage', !values.enabled_cloudStorage)}
                />
              </Box>
              <Box display="flex" alignItems="center">
                <Typography sx={{ fontSize: '14px', fontWeight: '400', lineHeight: '22px' }}>
                  LocalStorage status
                </Typography>
                <Switch
                  sx={{ m: 1 }}
                  checked={values.enabled_localStorage}
                  onChange={() => setFieldValue('enabled_localStorage', !values.enabled_localStorage)}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ padding: 0 }}>
            <Button style={{ width: 131 }} variant="outlined" onClick={onClose}>
              Đóng
            </Button>
            <Button type="submit" style={{ width: 131 }} variant="contained" disabled={!isValid || !dirty}>
              Lưu thay đổi
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Modal>
  );
};

import { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import DataEmpty from '../../../assets/img/data_empty.svg';
import { LocationType } from '../../../state/modules/location/locationReducer';
import { AddGatewayDialog } from './dialogs/AddGatewayDialog';
import { AddCameraBoxDialog } from './dialogs/AddCameraBoxDialog';

export const EmptyCameraBox = ({ location, refetch }: { location: LocationType; refetch: () => void }) => {
  const [openAddCameraBoxDialog, setOpenAddCameraBoxDialog] = useState(false);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight="500px">
      <Box>
        <img src={DataEmpty} alt="" style={{ width: '200px' }} />
        <Typography style={{ margin: '24px', color: '#C5C6D2' }}>Camera Box trống</Typography>
        <Button
          variant="outlined"
          style={{ width: '240px', borderRadius: '8px' }}
          startIcon={<AddCircleOutline />}
          onClick={() => setOpenAddCameraBoxDialog(true)}
        >
          Thêm Camera Box
        </Button>
      </Box>
      <AddCameraBoxDialog
        locationId={location.id}
        open={openAddCameraBoxDialog}
        onClose={() => {
          setOpenAddCameraBoxDialog(false);
          // refetch();
        }}
        onSuccess={refetch}
      />
    </Box>
  );
};

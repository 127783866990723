import React from 'react';
import { Box, Button, DialogActions, DialogContent } from '@mui/material';
import FormikWrappedField from '../../../../common/input/Field';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from '../../../../common/modal/Modal';
import {
  useAddCameraMutation,
  useLazyGetControlLocationCameraBoxsQuery,
  ControlLocationCameraBoxType,
} from '../../../../services/control.service';
import { useAuth } from '../../../../hooks/useAuth';
import { useSnackbar } from '../../../../hooks/useSnackbar';

interface Props {
  locationId: string;
  open: boolean;
  cameraBoxs: ControlLocationCameraBoxType[];
  onClose: () => void;
  onSuccess: () => void;
}

const validationSchema = {
  serial: Yup.string().required('Serial không được để trống'),
  name: Yup.string().required('Tên box không được để trống'),
  onvifhost: Yup.string().required('onvifhost không được để trống'),
  onvifport: Yup.string().required('onvifport không được để trống'),
  onvifusername: Yup.string().required('onvifusername không được để trống'),
  onvifpassword: Yup.string().required('onvifpassword không được để trống'),
};

export const AddCameraDialog: React.FC<Props> = ({ locationId, open, cameraBoxs, onClose, onSuccess }) => {
  const [addCameraControl] = useAddCameraMutation();
  const {
    auth: { currentUser },
  } = useAuth();

  const { setSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      serial: '',
      name: '',
      onvifhost: '',
      onvifport: '',
      onvifusername: '',
      onvifpassword: '',
      rtsp: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      ...validationSchema,
    }),
    onSubmit: (values) => {
      if (currentUser && locationId) {
        try {
          addCameraControl({
            agencyId: currentUser.sub_id,
            locationId: locationId,
            boxId: cameraBoxs[0]?.serial,
            data: values,
          }).then((res: any) => {
            if (res.error) {
              setSnackbar({ open: true, message: 'Có lỗi xảy ra khi thêm camera', severity: 'error' });
            } else {
              onSuccess();
              setSnackbar({ open: true, message: 'Thêm camera thành công', severity: 'success' });
            }
          });
          onClose?.();
        } catch (error) {
          setSnackbar({ open: true, message: 'Có lỗi xảy ra khi thêm camera', severity: 'error' });
        }
      }
    },
  });
  const { handleSubmit, getFieldProps, isValid, dirty } = formik;

  return (
    <Modal size="sm" show={open} close={onClose} title={'Thêm Camera vào Box'}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogContent
            sx={{
              padding: 0,
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              overflow: 'hidden',
              marginBottom: '32px',
            }}
          >
            <FormikWrappedField
              style={{ width: 286 }}
              placeholder="Nhập serial"
              topLable="Serial"
              {...getFieldProps('serial')}
            />
            <FormikWrappedField
              style={{ width: 286 }}
              placeholder="Nhập tên camera"
              topLable="Tên camera"
              {...getFieldProps('name')}
            />
            <FormikWrappedField
              style={{ width: 286 }}
              placeholder="Nhập tên đăng nhập onvif"
              topLable="Tên đăng nhập onvif"
              {...getFieldProps('onvifusername')}
            />
            <FormikWrappedField
              style={{ width: 286 }}
              placeholder="Nhập mật khẩu đăng nhập onvif"
              topLable="Mật khẩu đăng nhập onvif"
              {...getFieldProps('onvifpassword')}
            />
            <FormikWrappedField
              style={{ width: 286 }}
              placeholder="Cổng onvif"
              topLable="Cổng onvif"
              {...getFieldProps('onvifport')}
            />
            <FormikWrappedField
              style={{ width: 286 }}
              placeholder="Nhập onvifhost"
              topLable="Onvifhost"
              {...getFieldProps('onvifhost')}
            />
            <FormikWrappedField
              style={{ width: 600 }}
              placeholder="Nhập camera rtsp"
              topLable="Camera rtsp"
              {...getFieldProps('rtsp')}
            />
          </DialogContent>
          <DialogActions sx={{ padding: 0 }}>
            <Button style={{ width: 131 }} variant="outlined" onClick={onClose}>
              Đóng
            </Button>
            <Button type="submit" style={{ width: 131 }} variant="contained" disabled={!isValid || !dirty}>
              Thêm
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Modal>
  );
};

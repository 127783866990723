export const data = [
  {
    id: '',
    name: 'Xe tuần tra Thanh Xuân',
    address: 'Khương Đình, Thanh Xuân, Hà Nội',
    license_plate: '30H-78900',
    regulatory_agency: 'Bộ công an',
    type: 'Xe trọng yếu',
    phone: '0966888123',
    tag: ['@Ca_bocongan', '@Ca_bocongan'],
  },
  {
    id: '',
    name: 'Xe tuần tra Thanh Xuân',
    address: 'Khương Đình, Thanh Xuân, Hà Nội',
    license_plate: '30H-78900',
    regulatory_agency: 'Bộ công an',
    type: 'Xe trọng yếu',
    phone: '0966888123',
    tag: ['@Ca_bocongan', '@Ca_bocongan'],
  },
  {
    id: '',
    name: 'Xe tuần tra Thanh Xuân',
    address: 'Khương Đình, Thanh Xuân, Hà Nội',
    license_plate: '30H-78900',
    regulatory_agency: 'Bộ công an',
    type: 'Xe trọng yếu',
    phone: '0966888123',
    tag: ['@Ca_bocongan', '@Ca_bocongan'],
  },
  {
    id: '',
    name: 'Xe tuần tra Thanh Xuân',
    address: 'Khương Đình, Thanh Xuân, Hà Nội',
    license_plate: '30H-78900',
    regulatory_agency: 'Bộ công an',
    type: 'Xe trọng yếu',
    phone: '0966888123',
    tag: ['@Ca_bocongan', '@Ca_bocongan'],
  },
  {
    id: '',
    name: 'Xe tuần tra Thanh Xuân',
    address: 'Khương Đình, Thanh Xuân, Hà Nội',
    license_plate: '30H-78900',
    regulatory_agency: 'Bộ công an',
    type: 'Xe trọng yếu',
    phone: '0966888123',
    tag: ['@Ca_bocongan', '@Ca_bocongan'],
  },
  {
    id: '',
    name: 'Xe tuần tra Thanh Xuân',
    address: 'Khương Đình, Thanh Xuân, Hà Nội',
    license_plate: '30H-78900',
    regulatory_agency: 'Bộ công an',
    type: 'Xe trọng yếu',
    phone: '0966888123',
    tag: ['@Ca_bocongan', '@Ca_bocongan'],
  },
  {
    id: '',
    name: 'Xe tuần tra Thanh Xuân',
    address: 'Khương Đình, Thanh Xuân, Hà Nội',
    license_plate: '30H-78900',
    regulatory_agency: 'Bộ công an',
    type: 'Xe trọng yếu',
    phone: '0966888123',
    tag: ['@Ca_bocongan', '@Ca_bocongan'],
  },
  {
    id: '',
    name: 'Xe tuần tra Thanh Xuân',
    address: 'Khương Đình, Thanh Xuân, Hà Nội',
    license_plate: '30H-78900',
    regulatory_agency: 'Bộ công an',
    type: 'Xe trọng yếu',
    phone: '0966888123',
    tag: ['@Ca_bocongan', '@Ca_bocongan'],
  },
  {
    id: '',
    name: 'Xe tuần tra Thanh Xuân',
    address: 'Khương Đình, Thanh Xuân, Hà Nội',
    license_plate: '30H-78900',
    regulatory_agency: 'Bộ công an',
    type: 'Xe trọng yếu',
    phone: '0966888123',
    tag: ['@Ca_bocongan', '@Ca_bocongan'],
  },
  {
    id: '',
    name: 'Xe tuần tra Thanh Xuân',
    address: 'Khương Đình, Thanh Xuân, Hà Nội',
    license_plate: '30H-78900',
    regulatory_agency: 'Bộ công an',
    type: 'Xe trọng yếu',
    phone: '0966888123',
    tag: ['@Ca_bocongan', '@Ca_bocongan'],
  },
  {
    id: '',
    name: 'Xe tuần tra Thanh Xuân',
    address: 'Khương Đình, Thanh Xuân, Hà Nội',
    license_plate: '30H-78900',
    regulatory_agency: 'Bộ công an',
    type: 'Xe trọng yếu',
    phone: '0966888123',
    tag: ['@Ca_bocongan', '@Ca_bocongan'],
  },
  {
    id: '',
    name: 'Xe tuần tra Thanh Xuân',
    address: 'Khương Đình, Thanh Xuân, Hà Nội',
    license_plate: '30H-78900',
    regulatory_agency: 'Bộ công an',
    type: 'Xe trọng yếu',
    phone: '0966888123',
    tag: ['@Ca_bocongan', '@Ca_bocongan'],
  },
  {
    id: '',
    name: 'Xe tuần tra Thanh Xuân',
    address: 'Khương Đình, Thanh Xuân, Hà Nội',
    license_plate: '30H-78900',
    regulatory_agency: 'Bộ công an',
    type: 'Xe trọng yếu',
    phone: '0966888123',
    tag: ['@Ca_bocongan', '@Ca_bocongan'],
  },
  {
    id: '',
    name: 'Xe tuần tra Thanh Xuân',
    address: 'Khương Đình, Thanh Xuân, Hà Nội',
    license_plate: '30H-78900',
    regulatory_agency: 'Bộ công an',
    type: 'Xe trọng yếu',
    phone: '0966888123',
    tag: ['@Ca_bocongan', '@Ca_bocongan'],
  },
  {
    id: '',
    name: 'Xe tuần tra Thanh Xuân',
    address: 'Khương Đình, Thanh Xuân, Hà Nội',
    license_plate: '30H-78900',
    regulatory_agency: 'Bộ công an',
    type: 'Xe trọng yếu',
    phone: '0966888123',
    tag: ['@Ca_bocongan', '@Ca_bocongan'],
  },
  {
    id: '',
    name: 'Xe tuần tra Thanh Xuân',
    address: 'Khương Đình, Thanh Xuân, Hà Nội',
    license_plate: '30H-78900',
    regulatory_agency: 'Bộ công an',
    type: 'Xe trọng yếu',
    phone: '0966888123',
    tag: ['@Ca_bocongan', '@Ca_bocongan'],
  },
  {
    id: '',
    name: 'Xe tuần tra Thanh Xuân',
    address: 'Khương Đình, Thanh Xuân, Hà Nội',
    license_plate: '30H-78900',
    regulatory_agency: 'Bộ công an',
    type: 'Xe trọng yếu',
    phone: '0966888123',
    tag: ['@Ca_bocongan', '@Ca_bocongan'],
  },
];

import { Icon } from '@mui/material';
import { useAuth } from 'hooks/useAuth';
import { CSSProperties, ReactElement } from 'react';

export const ImageIcon = ({ image, style }: { image: string; style?: CSSProperties }) => {
  return (
    <Icon sx={{ width: '0.85em', height: '0.85em', display: 'flex', alignItems: 'center' }} style={style}>
      <img style={{ width: '100%' }} src={image} alt="" />
    </Icon>
  );
};

export const ActionPermission = ({
  children,
  allowedLevels,
}: {
  children: ReactElement;
  allowedLevels: (string | number)[];
}) => {
  const {
    auth: { currentAgency, currentUser },
  } = useAuth();

  if (currentAgency && !allowedLevels.includes(currentAgency.level)) {
    return null;
  }
  return children;
};

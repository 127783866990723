import React from 'react';
import { Box } from '@mui/material';
import CamerasBoxTable from './CamerasBoxTable';
import { useLazyGetListCameraQuery } from '../../services/cameras.service';
import { useAuth } from '../../hooks/useAuth';
import { CursorType } from '../../configs/constant';
import { useSelector } from 'react-redux';
import { selectCameraState, setLimit } from '../../state/modules/camera/cameraReducer';
import Pagination from '../../common/pagination/Pagination';
import { useAppDispatch } from '../../state/store';
import { StringParam, useQueryParams } from 'use-query-params';

export const CamerasScreen = () => {
  const [getCameras] = useLazyGetListCameraQuery();
  const [paginate, setPaginate] = React.useState<CursorType>({});
  const { cursor, limit, total } = useSelector(selectCameraState);
  const dispatch = useAppDispatch();
  const [query, setQuery] = useQueryParams({
    serial: StringParam,
  });

  const {
    auth: { currentUser },
  } = useAuth();

  React.useEffect(() => {
    if (currentUser) {
      getCameras({ 
        agencyId: currentUser?.sub_id, 
        params: { 
          limit, 
          ...paginate
         }
      });
    }
  }, [getCameras, paginate, currentUser, limit]);

  const handleSetLimit = (limit: number) => {
    dispatch(setLimit({ limit }));
  };
  return (
    <Box mt={2} ml={2} mr={'12px'}>
      <CamerasBoxTable setPaginate={setPaginate} />
      <Pagination paginate={cursor} total={total} setPaginate={setPaginate} limit={limit} setLimit={handleSetLimit} />
    </Box>
  );
};

import { DeleteOutline, Translate } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import DefaultImage from '../../../assets/img/default-image.jpg';
import ArrowRight from '../../../assets/icons/arrow-left-white.svg';
import ArrowLeft from '../../../assets/icons/arrow-right-white.svg';
import { ReactComponent as OpenFullScreenIcon } from '../../../assets/icons/fullscreen-icon.svg';
import { ReactComponent as CloseFullScreenIcon } from '../../../assets/icons/closefullscreen-icon.svg';
import useModalConfirm from '../../../hooks/useModalConfirm';

export const LocationImages = ({
  images,
  showFullscreenButton = true,
  onDeleteEventImage,
}: {
  images: { url: string; id: string }[];
  showFullscreenButton?: boolean;
  onDeleteEventImage?: (id: string) => void;
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { showModalConfirm, hideModalConfirm } = useModalConfirm();

  const imagesParsed =
    images.length > 0
      ? images.map((item) => ({
          original: item.url,
          thumbnail: item.url,
          fullscreen: item.url,
        }))
      : [
          {
            original: DefaultImage,
            thumbnail: DefaultImage,
            fullscreen: DefaultImage,
          },
        ];

  const _renderCustomControls = () => {
    return (
      onDeleteEventImage && (
        <DeleteOutline
          onClick={handleDelete}
          style={{ 
            position: 'absolute', 
            color: '#cecccc',
            top: '15px', 
            right: '15px', 
            zIndex: 1, 
            cursor: 'pointer',
          }}
        />
      )
    );
  };

  const handleDelete = () => {
    const itemDelete = images[currentIndex];
    if (!itemDelete) return;
    showModalConfirm({
      type: 'warning',
      title: 'Xoá ảnh',
      content: 'Bạn có chắc chắn muốn xoá ảnh này không?',
      confirm: {
        action: () => {
          if (onDeleteEventImage) {
            onDeleteEventImage(itemDelete.id);
          }
          hideModalConfirm();
        },
        text: 'Xoá',
      },
      cancel: {
        action: hideModalConfirm,
      },
    });
  };

  const ArrowButton = styled(Box)({
    '&:hover': {
      background: '#1E2323',
      transition: 'all 0.3s ease-in-out',
    },
    transform: 'Translate(0,-50%)',
  });

  const LeftArrowButton = ({ onClick }: any) => {
    return (
      <ArrowButton
        onClick={onClick}
        sx={{
          position: 'absolute',
          zIndex: '1000 !important',
          opacity: '50%',
          width: '34px',
          height: '54px',
          top: '50%',
          left: '0px',
          borderRadius: '0 4px 4px 0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          background: '#1e23237e',
        }}
      >
        <img src={ArrowLeft} />
      </ArrowButton>
    );
  };

  const RightArrowButton = ({ onClick }: any) => {
    return (
      <ArrowButton
        onClick={onClick}
        sx={{
          position: 'absolute',
          zIndex: '1000 !important',
          background: '#1e23237e',
          opacity: '50%',
          width: '34px',
          height: '54px',
          top: '50%',
          right: '0px',
          borderRadius: '4px 0 0 4px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <img src={ArrowRight} />
      </ArrowButton>
    );
  };

  interface FullscreenButtonProps {
    onClick: React.MouseEventHandler;
    isFullScreen: boolean;
  }

  const FullScreenButton = ({ onClick, isFullScreen }: FullscreenButtonProps) => {
    if (isFullScreen) {
      const queryElementImage = document.getElementsByClassName('image-gallery-image');
      queryElementImage[0]?.setAttribute('class', 'image-gallery-image-custom');
    } else {
      const queryElementImage = document.getElementsByClassName('image-gallery-image-custom');
      queryElementImage[0]?.setAttribute('class', 'image-gallery-image');
    }

    return isFullScreen ? (
      <Box
        onClick={onClick}
        sx={{
          position: 'absolute',
          bottom: '30px',
          right: '30px',
          cursor: 'pointer',
        }}
      >
        <CloseFullScreenIcon />
      </Box>
    ) : (
      <Box
        onClick={onClick}
        sx={{
          position: 'absolute',
          bottom: '30px',
          right: '30px',
          cursor: 'pointer',
        }}
      >
        <OpenFullScreenIcon />
      </Box>
    );
  };

  return (
    <Box pl={1}>
      <ImageGallery
        showFullscreenButton={showFullscreenButton}
        items={imagesParsed}
        onScreenChange={(isFullScreen) => setIsFullScreen(isFullScreen)}
        renderCustomControls={_renderCustomControls}
        onSlide={(index) => setCurrentIndex(index)}
        showPlayButton={false}
        renderLeftNav={(onClick) => <LeftArrowButton onClick={onClick} />}
        renderRightNav={(onClick) => <RightArrowButton onClick={onClick} />}
        renderFullscreenButton={(onClick, isFullScreen) => (
          <FullScreenButton onClick={onClick} isFullScreen={isFullScreen} />
        )}
      />
    </Box>
  );
};

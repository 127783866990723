import { Box } from '@mui/material';
import ReportsTable from './ReportsTable';

const ReportsScreen = () => {
  return (
    <Box mt={2} ml={2} mr={'12px'}>
      <ReportsTable />
    </Box>
  );
};

export default ReportsScreen;

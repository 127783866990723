export interface TagType {
  tag: string;
  name: string;
}

export const dataTag = [
  {
    tag: '@Dawson',
    name: 'Collins',
  },
  {
    tag: '@Freeman',
    name: 'Owen',
  },
  {
    tag: '@Foster',
    name: 'McLaughlin',
  },
  {
    tag: '@Tran',
    name: 'Silva',
  },
  {
    tag: '@Chambers',
    name: 'Quinn',
  },
  {
    tag: '@Knight',
    name: 'Keller',
  },
  {
    tag: '@Schmidt',
    name: 'Peterson',
  },
  {
    tag: '@Herrera',
    name: 'Christensen',
  },
  {
    tag: '@Pratt',
    name: 'Lopez',
  },
  {
    tag: '@Edwards',
    name: 'Schwartz',
  },
  {
    tag: '@Mitchell',
    name: 'Obrien',
  },
];

import { createApi } from '@reduxjs/toolkit/query/react';
import { setCameras } from '../state/modules/camera/cameraReducer';
import { queryRootConfig, ResponsiveInterface } from './http.service';

export interface ICameraType {
  camId: string;
  serial: string;
  boxid: string;
  boxserial: string;
  name: string;
  status: string;
  onvifhost: string;
  onvifport: string;
  onvifusername: string;
  onvifpassword: string;
  audiocodec: string;
  videocodec: string;
  websocketstream: string;
}

export interface ICameraBoxType {
  id: string;
  name: string;
  serial: string;
  agency_id: string;
  version: string;
  status: number;
  blocking: boolean;
}

export interface DeltailCameraBoxResponsiveInterface extends ResponsiveInterface {
  data: ICameraBoxType;
}

export interface CameraBoxRequestInterface {
  agencyId: string;
  camera_box: {
    camera_type_id?: string;
    serial: string;
    name: string;
    hub_serial?: string;
    version: string;
    mfg?: number;
  };
}

export const camerasApi = createApi({
  ...queryRootConfig,
  reducerPath: 'CamerasApi',
  tagTypes: ['Camera', 'CameraType'],
  endpoints: (build) => ({
    getListCamera: build.query<any, { agencyId?: string; params: any }>({
      query: (body) => ({ url: `agencies/${body.agencyId}/cameraboxs`, params: body.params }),
      providesTags() {
        return [{ type: 'Camera' }];
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { data, cursor, total },
          } = await queryFulfilled;
          dispatch(
            setCameras({
              cameras: data,
              cursor: cursor,
              total: total,
            })
          );
        } catch (error) {}
      },
    }),
    createCamera: build.mutation<any, any>({
      query: ({ data, agencyId }) => {
        try {
          return {
            url: `agencies/${agencyId}/cameraboxs`,
            method: 'POST',
            body: data,
          };
        } catch (error: any) {
          throw new error.message();
        }
      },
      invalidatesTags: (result, error, data) => (error ? [] : [{ type: 'Camera' }]),
    }),
    updateCamera: build.mutation<any, any>({
      query: ({ data, agencyId }) => {
        try {
          return {
            url: `agencies/${agencyId}/cameraboxs/${data.id}`,
            method: 'PUT',
            body: data,
          };
        } catch (error: any) {
          throw new error.message();
        }
      },
      invalidatesTags: (result, error, data) => (error ? [] : [{ type: 'Camera' }]),
    }),
    deleteCamera: build.mutation<any, { ids: (number | string)[]; agencyId: string }>({
      query: ({ ids, agencyId }) => {
        try {
          return {
            url: `agencies/${agencyId}/cameraboxs`,
            method: 'DELETE',
            body: { ids },
          };
        } catch (error: any) {
          throw new error.message();
        }
      },
      invalidatesTags: (result, error, data) => (error ? [] : [{ type: 'Camera' }]),
    }),
  }),
});

export const {
  useCreateCameraMutation,
  useGetListCameraQuery,
  useLazyGetListCameraQuery,
  useUpdateCameraMutation,
  useDeleteCameraMutation,
} = camerasApi;

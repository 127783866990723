import React from 'react';
import { Box, Button, DialogActions } from '@mui/material';
import FormikWrappedField from '../../../../common/input/Field';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from '../../../../common/modal/Modal';
import { useAddCameraBoxMutation } from '../../../../services/control.service';
import { useAuth } from '../../../../hooks/useAuth';
import { useSnackbar } from '../../../../hooks/useSnackbar';

interface Props {
  locationId: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const validationSchema = {
  serial: Yup.string().required('Serial không được để trống'),
  name: Yup.string().required('Tên camera box không được để trống'),
};

export const AddCameraBoxDialog: React.FC<Props> = ({ locationId, open, onClose, onSuccess }) => {
  const [addCameraBoxControl] = useAddCameraBoxMutation();
  const {
    auth: { currentUser },
  } = useAuth();

  const { setSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      serial: '',
      name: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      ...validationSchema,
    }),
    onSubmit: (values, event) => {
      if (currentUser && locationId) {
        try {
          addCameraBoxControl({
            agencyId: currentUser.sub_id,
            locationId: locationId,
            data: values,
          }).then((res: any) => {
            if (res.error) {
              setSnackbar({ open: true, message: 'Có lỗi xảy ra khi thêm camera box', severity: 'error' });
            } else {
              onSuccess();
              setSnackbar({ open: true, message: 'Thêm camera box thành công', severity: 'success' });
            }
          });
          onClose?.();
        } catch (error) {
          setSnackbar({ open: true, message: 'Có lỗi xảy ra khi thêm camera box', severity: 'error' });
        }
      }
    },
  });
  const { handleSubmit, getFieldProps, isValid, dirty } = formik;

  return (
    <Modal size="sm" show={open} close={onClose} title={'Thêm Camera Box'}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box mb={3}>
            <FormikWrappedField fullWidth placeholder="Nhập Serial" topLable="Serial" {...getFieldProps('serial')} />
          </Box>
          <Box mb={3}>
            <FormikWrappedField fullWidth placeholder="Nhập tên Box" topLable="Tên Camera Box" {...getFieldProps('name')} />
          </Box>
          <DialogActions sx={{ padding: 0 }}>
            <Button style={{ width: 131 }} variant="outlined" onClick={onClose}>
              Đóng
            </Button>
            <Button type="submit" style={{ width: 131 }} variant="contained" disabled={!isValid || !dirty}>
              Thêm
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Modal>
  );
};

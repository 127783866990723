export interface CameraBoxType {
  agency_id?: string;
  blocking?: boolean;
  id: string;
  name: string;
  serial: string;
  status?: number;
  version: string;
  active_at?: string;
}

export interface CameraType {
  camId: string;
  serial: string;
  boxid: string;
  boxserial: string;
  name: string;
  status: string;
  onvifhost: string;
  onvifport: string;
  onvifusername: string;
  onvifpassword: string;
  audiocodec: string;
  videocodec: string;
  websocketstream: string;
}

export const defaultValuesCamera = {
  agency_id: '',
  blocking: false,
  id: '',
  name: '',
  number: '',
  serial: '',
  status: 1,
  version: '',
  active_at: '',
};

export const listStatus = [
  {
    value: 1,
    label: 'lưu kho',
  },
  {
    value: 0,
    label: 'Không lưu',
  },
];

export const LIST_STATUS_CAMERA_BOX = {
  ACTIVE: '2',
  INACTIVE: '1',
};

const { ACTIVE, INACTIVE } = LIST_STATUS_CAMERA_BOX;

export const mappingStatusCameraBox = {
  [ACTIVE]: 'Active',
  [INACTIVE]: 'Inactive',
};

export const mappingStatusCameraBoxColor = {
  [ACTIVE]: '#27AE60',
  [INACTIVE]: '#ae2727',
};

export const listStatusCameraBox = Object.keys(mappingStatusCameraBoxColor).map((item) => ({
  value: item,
  label: mappingStatusCameraBoxColor[item],
}));

export const listStatusCameraBoxLess = listStatusCameraBox.slice(1);

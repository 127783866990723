export const ROUTE_HOME = '/';
export const ROUTE_REGULATORY_AGENCY = '/regulatory-agency';
export const ROUTE_AGENCY = '/agencies';
export const ROUTE_USER = '/users';
export const ROUTE_REPORTS = '/reports';
export const ROUTE_WAREHOUSES = '/warehouse';
export const ROUTE_WAREHOUSESIM = '/warehouse-sim';
export const ROUTE_WAREHOUSE_NODE = '/warehouse-node';
export const ROUTE_WAREHOUSE_GATEWAY = '/warehouse-gateway';
export const ROUTE_WAREHOUSE_CAMERA = '/warehouse-cameras';
export const ROUTE_VEHICLE_PROTECT = '/vehicle-protect';
export const ROUTE_VITAL_VEHICLE = '/vehicle-vital';
export const MONITOR_DEPARTMENT = '/organizations';
export const DEPLOY_LOCATION = '/depoy-location';
export const ROUTE_CONTROL = '/control';
export const APP_USER = '/app-users';

export const routeTitle = {
  control: 'Giám sát',
  agencies: 'Đại lý',
  users: 'Nhân viên',
  'warehouse-gateway': 'Kho Gateway',
  'warehouse-node': 'Kho Node',
  'warehouse-sim': 'Kho Sim',
  'warehouse-cameras': 'Kho Camera Box',
  'depoy-location': 'Vị trí triển khai',
  'regulatory-agency': 'Cơ quan chức năng',
  organizations: 'Đơn vị giám sát',
  'app-users': 'Người dùng',
};

import { IOrganization } from 'services/organizations.service';

export const defaultInitialValue: IOrganization = {
  id: '',
  name: '',
  address: '',
  tag: '',
  parent_id: '',
  username: '',
  password: '',
};

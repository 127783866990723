import icon_error from '../assets/sensor/icon_error.svg';
import icon_check_circle from '../assets/sensor/icon_check_circle.svg';
import error_loading from '../assets/sensor/error-loading.svg';
import door_sensor from '../assets/sensor/door.svg';
import gas_sensor from '../assets/sensor/gas.svg';
import heat_sensor from '../assets/sensor/heat.svg';
import ic_vibration_move_sensor from '../assets/sensor/ic_vibration_move.svg'; //cam bien dich chuyen
import move_sensor from '../assets/sensor/move-sensor.svg'; // cam bien chuyen dong
import remote_sos_sensor from '../assets/sensor/remote-sos.svg'; // remove sos
import heat_humid_sensor from '../assets/sensor/nhietdo-doam.svg';
import smoke_sensor from '../assets/sensor/smoke.svg';
import smoke_heat_sensor from '../assets/sensor/smoke-heat.svg';
import speaker_sensor from '../assets/sensor/speaker.svg'; // loa den bao dong
import impact_sensor from '../assets/sensor/va-dap.svg';
import power_on from '../assets/sensor/power-on.svg';
import power_off from '../assets/sensor/power-off.svg';
import power_percent from '../assets/sensor/renewable-energy-battery-leaf.svg';
import power_low_percent from '../assets/sensor/enery_low_battery.svg';
import power_on_dot from '../assets/sensor/power-on-dot.svg';
import power_off_dot from '../assets/sensor/power-off-dot.svg';
import status_on from '../assets/sensor/status-on.svg';
import status_off from '../assets/sensor/status-off.svg';
import power_100 from '../assets/sensor/power-100.svg';
import power_75 from '../assets/sensor/power-75.svg';
import power_50 from '../assets/sensor/power-50.svg';
import power_25 from '../assets/sensor/power-25.svg';
import power_10 from '../assets/sensor/power-10.svg';
import spark from '../assets/sensor/spark.svg'; //tia lua;
import uploadIcon from '../assets/sensor/upload.svg';
import ASBL1 from '../assets/sensor/speaker.svg';
import SSM from '../assets/sensor/speaker.svg';
import SSM1 from '../assets/sensor/speaker.svg';
import RCB4 from '../assets/sensor/remote-sos.svg';
import RSBE from '../assets/sensor/remote-sos.svg';
import RSBR from '../assets/sensor/remote-sos.svg';
import SRB from '../assets/sensor/remote-sos.svg';
import REMOTE from '../assets/sensor/remote.svg';
import SRB1 from '../assets/sensor/remote-sos.svg';
import SOS from '../assets/sensor/remote-sos.svg';
import SDB from '../assets/sensor/door.svg';
import DOOR from '../assets/sensor/door.svg';
import SFDB from '../assets/sensor/spark.svg';
import FM from '../assets/sensor/spark.svg';
import FM1 from '../assets/sensor/spark.svg';
import SGB from '../assets/sensor/gas.svg';
import SHB from '../assets/sensor/heat.svg';
import HEAT from '../assets/sensor/heat.svg';
import SPB from '../assets/sensor/move-sensor.svg';
import PIR from '../assets/sensor/move-sensor.svg';
import SSB from '../assets/sensor/smoke-heat.svg';
import SB from '../assets/sensor/smoke-heat.svg';
import SB1 from '../assets/sensor/smoke-heat.svg';
import SSHB from '../assets/sensor/smoke-heat.svg';
import SMOKE from '../assets/sensor/smoke.svg';
import STHB from '../assets/sensor/nhietdo-doam.svg';
import THB from '../assets/sensor/hum-temp-icon.svg';
import SVB from '../assets/sensor/va-dap.svg';
import VIBRATE from '../assets/sensor/va-dap.svg';
import SMB from '../assets/sensor/ic_vibration_move.svg'; //cam bien dich chuyen
import alertImage from '../assets/sensor/alert.svg';
import A01 from '../assets/sensor/building.svg';
import A4E from '../assets/sensor/atm.png';
import bao_chay from '../assets/sensor/bao_chay.png';
import ZONE from '../assets/sensor/zone.svg';
import ARB from '../assets/sensor/energy-icon.svg';
import ROUTER from '../assets/sensor/router-icon.svg';
import PRESENCE from '../assets/sensor/presence.svg';
import A100 from '../assets/img/atmIcon.png';
import A200 from '../assets/img/atmIcon.png';
import A300 from '../assets/img/atmIcon.png';
import A400 from '../assets/img/atmIcon.png';
import A500 from '../assets/img/atmIcon.png';

export const sensorMapped = {
  icon_error,
  icon_check_circle,
  error_loading,
  door_sensor,
  gas_sensor,
  heat_sensor,
  ic_vibration_move_sensor, //cam bien dich chuyen
  move_sensor, // cam bien chuyen dong
  remote_sos_sensor, // remove sos
  heat_humid_sensor,
  smoke_sensor,
  smoke_heat_sensor,
  speaker_sensor, // loa den bao dong
  impact_sensor,
  power_on,
  power_off,
  power_percent,
  power_low_percent,
  power_on_dot,
  power_off_dot,
  status_on,
  status_off,
  power_100,
  power_75,
  power_50,
  power_25,
  power_10,
  spark, //tia lua,
  uploadIcon,
  ASBL1,
  SSM,
  SSM1,
  RCB4,
  RSBE,
  RSBR,
  SRB,
  SRB1,
  SDB,
  SFDB,
  FM,
  FM1,
  SGB,
  SHB,
  SPB,
  SSB,
  SB,
  SB1,
  SSHB,
  STHB,
  THB,
  SVB,
  SMB, //cam bien dich chuyen
  alertImage,
  A01,
  A4E,
  bao_chay,
  ZONE,
  SOS,
  ARB,
  ROUTER,
  SMOKE,
  DOOR,
  VIBRATE,
  HEAT,
  PIR,
  REMOTE,
  PRESENCE,
  A100,
  A200,
  A300,
  A400,
  A500
};

import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Badge,
  Button,
  ButtonGroup,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material';
import { AddCircleOutline, ArrowDropDown } from '@mui/icons-material';
//import ActiveCameraIcon from '../../../assets/icons/active-camera-icon.svg';
import ActiveCameraIcon from '../../../assets/icons/active-camera-icon-black.svg';
import CameraIcon from '../../../assets/icons/camera-icon.svg';
import { CameraLive } from './CameraLive';
import {
  ControlLocationCameraType,
  useLazyGetControlLocationCamerasQuery,
  useLazyGetControlLocationCameraImageQuery,
  ControlLocationCameraBoxType,
  useLazyGetControlLocationCameraBoxsQuery,
  useRemoveCameraMutation,
  useRemoveEventImageMutation,
} from '../../../services/control.service';
import { useAuth } from '../../../hooks/useAuth';
import { LocationType } from '../../../state/modules/location/locationReducer';
import { AddCameraDialog } from './dialogs/AddCameraDialog';
import { LocationImages } from './LocationImages';
import dayjs from 'dayjs';
import { Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { MoreHoriz } from '@mui/icons-material';
import { ImageIcon } from '../../../utils/UtilsComponent';
import ActiveProductIcon from '../../../assets/icons/product-active-icon.svg';
import { EditCameraDialog } from './dialogs/EditCameraDialog';
import useModalConfirm from 'hooks/useModalConfirm';
import { useSnackbar } from 'hooks/useSnackbar';
import { getMinutesDiffNow } from 'utils/UtilsFunctions';

export const defaultInitialValues = {
  boxid: '',
  camId: '',
  name: '',
  serial: '',
};

export const CameraControl = ({ location, onRefresh }: { location: LocationType; onRefresh: () => void }) => {
  const [getControlCameras, { data }] = useLazyGetControlLocationCamerasQuery();
  const [getControlCameraImages, { data: imagesData }] = useLazyGetControlLocationCameraImageQuery();
  const [getControlLocationCameraBoxs, { data: cameraBoxs }] = useLazyGetControlLocationCameraBoxsQuery();
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [imageMode, setImageMode] = useState(true);
  const [selectedCamera, setSelectedCamera] = useState<ControlLocationCameraType>();
  const [selectedCameraAction, setSelectedCameraAction] = useState<ControlLocationCameraType>();
  const [selectedCameraBox, setSelectedCameraBox] = useState<ControlLocationCameraBoxType>();
  const { showModalConfirm, hideModalConfirm } = useModalConfirm();
  const [deleteCamInBox] = useRemoveCameraMutation();

  const [modalEdit, setModalEdit] = useState(defaultInitialValues);
  const [removeEventImage] = useRemoveEventImageMutation();
  const { setSnackbar } = useSnackbar();

  const {
    auth: { currentUser },
  } = useAuth();

  const onChangeMode = () => {
    setImageMode(!imageMode);
  };
  
  useEffect(() => {
    if (currentUser && location) {
      getControlLocationCameraBoxsFetch();
    }
  }, [currentUser, location]);

  useEffect(() => {
    if (currentUser && location) {
      getControlLocationCameraBoxsFetch();
    }
  }, [location]);

  useEffect(() => {
    if (currentUser && location && cameraBoxs) {
      getControlLocationCamerasFetch();
    }
  }, [currentUser, location, cameraBoxs]);

  useEffect(() => {
    if (currentUser && location && selectedCamera) {
      getControlCameraImagesFetch();
    }
  }, [currentUser, location, selectedCamera]);

  useEffect(() => {
    if (data && data.length > 0) {
      setSelectedCamera(data[0]);
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (location && location.state === 'alert') {
        getControlCameraImagesFetch();
        getControlLocationCameraBoxsFetch();
      }
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [location]);

  const getControlLocationCamerasFetch = async () => {
    const cameraBox = cameraBoxs[0];
    if (currentUser && location && cameraBox) {
      await getControlCameras(
        {
          agencyId: currentUser.sub_id,
          locationId: location.id,
          cameraBoxId: cameraBox?.id,
        },
        false
      ).unwrap();
    }
  };

  const getControlLocationCameraBoxsFetch = async () => {
    if (currentUser && location) {
      await getControlLocationCameraBoxs(
        {
          agencyId: currentUser.sub_id,
          locationId: location.id,
        },
        false
      ).unwrap();
    }
  };

  const getControlCameraImagesFetch = async () => {
    if (currentUser && location && selectedCamera) {
      await getControlCameraImages(
        {
          agencyId: currentUser.sub_id,
          locationId: location.id,
          cameraboxeId: selectedCamera.boxserial,
          cameraId: selectedCamera.camId,
        },
        false
      ).unwrap();
    }
  };

  const cameras = (data || []) as ControlLocationCameraType[];
  
  const cameBox = (cameraBoxs || []) as ControlLocationCameraBoxType[];
 
  const timeStamp = cameBox ? cameBox[0]?.state?.timestamp : '';
  const status = cameBox[0]?.status;
  const activeMode = useMemo(() => {
    return timeStamp && getMinutesDiffNow(timeStamp) < 5.05 && status === 2 ? 'Online' : 'Mất kết nối';
  }, [timeStamp, status]);
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement | undefined>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>, cameraData: ControlLocationCameraType) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedCameraAction(cameraData);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (camera: ControlLocationCameraType) => {
    if (selectedCameraAction) {
      setOpenEdit(true);
      setAnchorEl(undefined);
      const valueEdit = {
        boxid: selectedCameraAction.boxid,
        camId: selectedCameraAction.camId,
        name: selectedCameraAction.name,
        serial: selectedCameraAction.serial,
      };
      setModalEdit(valueEdit);
    }
  };

  const handleDeleteCamera = (row: ControlLocationCameraType) => {
    if (currentUser) {
      deleteCamInBox({
        agencyId: currentUser.sub_id,
        locationId: location.id,
        camId: row.camId,
        boxId: row.boxserial,
      }).then((res) => {
        if (res && 'data' in res && res.data.success) {
          setSnackbar({ open: true, message: 'Xóa camera khỏi box thành công', severity: 'success' });
        } else {
          setSnackbar({ open: true, message: 'Có lỗi khi xóa camera', severity: 'error' });
        }
      });
      hideModalConfirm();
      onRefresh();
    }
  };

  const handleConfirmDelete = () => {
    if (selectedCameraAction) {
      setAnchorEl(undefined);
      showModalConfirm({
        type: 'warning',
        title: 'Xoá camera',
        content: 'Bạn có chắc chắn muốn xoá camera này không?',
        confirm: {
          action: () => handleDeleteCamera(selectedCameraAction),
          text: 'Xoá camera',
        },
        cancel: {
          action: hideModalConfirm,
        },
      });
    }
  };

  const onDeleteEventImage = (imgId: string) => {
    if (currentUser && location) {
      removeEventImage({ agencyId: currentUser.sub_id, locationId: location.id, eventId: imgId })
        .then((res: any) => {
          if (res.error) {
            setSnackbar({ open: true, message: 'Có lỗi khi xoá ảnh này', severity: 'error' });
            return;
          }
          hideModalConfirm();
          setSnackbar({ open: true, message: 'Xoá ảnh ảnh thành công', severity: 'success' });
          getControlCameraImagesFetch();
        })
        .catch(() => setSnackbar({ open: true, message: 'Có lỗi khi xoá ảnh', severity: 'error' }));
    }
  };
  
  return (
    <Box mr={'12px'}>
      <Grid container spacing={1}>
        <Grid item xs={3} style={{ borderRight: '1px solid #EEF2FA', paddingRight: '16px' }}>
          <Box pb={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              {/* <Typography style={{ fontWeight: 500 }}>Danh sách camera</Typography> */}
              {/* {cameras.length === 0 && (
                <IconButton onClick={() => setOpenAdd(true)}>
                  <AddCircleOutline />
                </IconButton>
              )} */}
            </Box>
            <Box mt={2}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pb="12px"
                style={{ borderBottom: '1px solid #EEF2FA' }}
              >
                <Box display="flex" alignItems="center">
                  <Box>
                    <img src={ActiveProductIcon} alt="" />
                  </Box>
                  <Box px={2}>
                    <Typography style={{ color: '#8F0A0C', fontWeight: 600, fontSize: '14px' }}>CAMERA BOX</Typography>
                    <Typography style={{ color: '#8F0A0C', fontSize: '12px' }}>{cameras.length} camera</Typography>
                  </Box>
                </Box>
                {/* <ArrowDropDown style={{ color: '#8F0A0C' }} /> */}
                <IconButton onClick={() => setOpenAdd(true)}>
                  <AddCircleOutline />
                </IconButton>
              </Box>
              <Box maxHeight="300px" overflow="auto">
                {cameras.map((camera, index) => (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent={'space-between'}
                      py="12px"
                      style={{ borderBottom: '1px solid #EEF2FA', cursor: 'pointer' }}
                      onClick={() => setSelectedCamera(camera)}
                    >
                      <Box display="flex" alignItems="center">
                        <Box>
                          <Badge color="success" variant="dot" invisible={false}>
                            <img src={selectedCamera?.camId === camera.camId ? ActiveCameraIcon : CameraIcon} alt="" />
                          </Badge>
                        </Box>
                        <Box px={2}>
                          <Typography
                            style={{
                              color: selectedCamera?.camId === camera.camId ? '#000000' : '#8B8C9B',
                              fontWeight: 600,
                              fontSize: '14px',
                            }}
                          >
                            CAM {index + 1} - {camera.name}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <IconButton
                          id="demo-positioned-button"
                          aria-controls={open ? 'demo-positioned-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={(event) => handleClick(event, camera)}
                        >
                          <MoreHoriz />
                        </IconButton>
                      </Box>
                    </Box>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <MenuItem sx={{ padding: '16px' }}>
                        <ListItemText primaryTypographyProps={{ sx: { fontSize: '14px' } }}>
                          Lưu video cloud
                        </ListItemText>
                      </MenuItem>
                      <Divider sx={{ margin: '0 16px !important' }} />
                      <MenuItem sx={{ padding: '16px' }}>
                        <ListItemText primaryTypographyProps={{ sx: { fontSize: '14px' } }}>
                          Lưu tại thiết bị
                        </ListItemText>
                      </MenuItem>
                      <Divider sx={{ margin: '0 16px !important' }} />
                      <MenuItem sx={{ padding: '16px' }}>
                        <ListItemText primaryTypographyProps={{ sx: { fontSize: '14px' } }}>Gửi cảnh báo</ListItemText>
                      </MenuItem>
                      <Divider sx={{ margin: '0 16px !important' }} />
                      <MenuItem sx={{ padding: '16px' }}>
                        <ListItemText
                          primaryTypographyProps={{ sx: { fontSize: '14px' } }}
                          onClick={() => handleEdit(camera)}
                        >
                          Sửa thông tin
                        </ListItemText>
                      </MenuItem>
                      <Divider sx={{ margin: '0 16px !important' }} />
                      <MenuItem sx={{ padding: '16px' }} onClick={() => handleConfirmDelete()}>
                        <ListItemText primaryTypographyProps={{ sx: { fontSize: '14px', color: '#E5401C' } }}>
                          Xóa camera
                        </ListItemText>
                      </MenuItem>
                    </Menu>
                  </>
                ))}
              </Box>
            </Box>
          </Box>
          {selectedCamera && cameras?.length > 0 && (
            <Box>
              <Box display="flex" alignItems="center">
                <Typography style={{ fontWeight: 500 }}>Thông tin Camera</Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between" py="12px">
                <Typography style={{ color: '#8B8C9B', fontSize: '14px' }}>Tên Camera:</Typography>
                <Typography style={{ fontSize: '14px' }}>{selectedCamera.name}</Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between" py="12px">
                <Typography style={{ color: '#8B8C9B', fontSize: '14px' }}>Mã thiết bị:</Typography>
                <Typography style={{ fontSize: '14px' }}>{selectedCamera.camId}</Typography>
              </Box>
              {currentUser?.type === 'agency' && (
              <Box display="flex" alignItems="center" justifyContent="space-between" py="12px">
                <Typography style={{ color: '#8B8C9B', fontSize: '14px' }}>Tên đăng nhập onvif:</Typography>
                <Typography style={{ fontSize: '14px' }}>{selectedCamera.onvifusername}</Typography>
              </Box>
              )}
               {currentUser?.type === 'agency' && (
              <Box display="flex" alignItems="center" justifyContent="space-between" py="12px">
                <Typography style={{ color: '#8B8C9B', fontSize: '14px' }}>Mật khẩu đăng nhập onvif:</Typography>
                <Typography style={{ fontSize: '14px' }}>{selectedCamera.onvifpassword}</Typography>
              </Box>
               )}
              <Box display="flex" alignItems="center" justifyContent="space-between" py="12px">
                <Typography style={{ color: '#8B8C9B', fontSize: '14px' }}>Cổng onvif:</Typography>
                <Typography style={{ fontSize: '14px' }}>{selectedCamera.onvifport}</Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between" py="12px">
                <Typography style={{ color: '#8B8C9B', fontSize: '14px' }}>Trạng thái:</Typography>
                <Typography style={{ fontSize: '14px' }}>{selectedCamera?.state?.isOnline === true ? "Online" : "Offline"}</Typography>
              </Box>
              <Divider
                sx={{ margin: '10px 0 16px 0 !important', color: '#8B8C9B !important', opacity: '50% !important' }}
              />
            </Box>
          )}

          {cameBox.map((camBox, index) => {
            return (
              <Box>
                <Box display="flex" alignItems="center" sx={{ marginTop: 1 }}>
                  <Typography style={{ fontWeight: 500 }}>Camera Box</Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" py="12px">
                  <Typography style={{ color: '#8B8C9B', fontSize: '14px' }}>Mã thiết bị:</Typography>
                  <Typography style={{ fontSize: '14px' }}>{camBox.serial}</Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" py="12px">
                  <Typography style={{ color: '#8B8C9B', fontSize: '14px' }}>Phiên bản:</Typography>
                  <Typography style={{ fontSize: '14px' }}>{camBox.version}</Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" py="12px">
                  <Typography style={{ color: '#8B8C9B', fontSize: '14px' }}>Trạng thái:</Typography>
                  <Typography style={{ fontSize: '14px' }}>
                    {activeMode}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" py="12px">
                  <Typography style={{ color: '#8B8C9B', fontSize: '14px' }}>Cập nhật lần cuối:</Typography>
                  <Typography style={{ fontSize: '14px' }}>
                    {timeStamp? dayjs(timeStamp * 1000).format('DD/MM/YYYY HH:mm') : '--'}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Grid>
        <Grid item xs={9} style={{ paddingLeft: '8px' }}>
          <Box display="flex" justifyContent="left" my={2} sx={{ paddingLeft: '6px' }}>
            <ButtonGroup disableElevation>
              <Button
                style={{
                  textTransform: 'none',
                  width: '140px',
                  borderRadius: '16px',
                  marginRight: '-30px',
                  padding: '10px 12px',
                  height: '38px',
                  zIndex: imageMode ? 1 : undefined,
                }}
                onClick={onChangeMode}
                variant={imageMode ? 'contained' : 'outlined'}
                color={imageMode ? 'primary' : undefined}
              >
                <Typography color={imageMode ? undefined : 'primary'} style={{ fontSize: '14px', fontWeight: 700 }}>
                  Hình ảnh
                </Typography>
              </Button>
              <Button
                style={{
                  textTransform: 'none',
                  width: '140px',
                  borderRadius: '16px',
                  padding: '10px 12px',
                  height: '38px',
                }}
                onClick={onChangeMode}
                variant={!imageMode ? 'contained' : 'outlined'}
                color={!imageMode ? 'primary' : undefined}
              >
                <Typography color={!imageMode ? undefined : 'primary'} style={{ fontSize: '14px', fontWeight: 700 }}>
                  Trực tiếp
                </Typography>
              </Button>
            </ButtonGroup>
          </Box>
          {selectedCamera && (
            <Box sx={{ position: 'relative' }}>
              {imageMode ? (
                <LocationImages images={imagesData || []} onDeleteEventImage={onDeleteEventImage} />
              ) : (
                <CameraLive
                  camera={selectedCamera}
                  isAlert={
                    location.state === 'alert' &&
                    !!location.alert_at &&
                    dayjs(location.alert_at).add(15, 'minute').isAfter(dayjs())
                  }
                />
              )}
            </Box>
          )}
        </Grid>
      </Grid>
      {openAdd && (
        <AddCameraDialog
          locationId={location.id}
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          onSuccess={getControlLocationCamerasFetch}
          cameraBoxs={cameraBoxs}
        />
      )}

      {openEdit && (
        <EditCameraDialog
          locationId={location.id}
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          onSuccess={getControlLocationCamerasFetch}
          initialValues={modalEdit}
        />
      )}
    </Box>
  );
};
